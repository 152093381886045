
import {
  agentApplicationsMapActions,
  agentApplicationsMapMutations,
  agentApplicationsMapState
} from "@/store/modules/agentApplications";
import * as types from "@/store/mutation-types";
import Vue from "vue";
import * as selectOptions from "@/helpers/selectOptions";
import { getDefaultAgentApplications } from "@/helpers/defaultAgentApplication";
import { objectDifference } from "@/helpers";
import { IAgentApplications } from "@/store/modules/agentApplications/types";
import testData from "./testData";
import { agentApplicationForm } from "@/forms/shared/agentApplication";
import { omit } from "lodash";
import { sameAsPhysicalAddressChangedHandler } from "@/helpers/isSameAsPhysicalAddress";
import PageWrapDefault from "@/views/wrappers/PageWrapDefault.vue";
import { formatCurrencyFloat } from "@/plugins/filters";
import { logoPath } from "@/helpers/generalHelpers";

interface ICreateAgentApplication {
  errorMessage: string;
  successMessage: string;
  entityTypeOptions: any[];
  hasValidApplicationData: boolean;
  checkEntityType: boolean;
  stateOptions: any[];
  streetTypeOptions: any[];
  streetDirectionOptions: any[];
  throughMGA: any;
  currCarrier: any;
  comparativeRater: any;
  loading: boolean;
  checkboxOptions: any[];
  isAdmin: boolean;
  testData: IAgentApplications;
  nextRoute: any;
  validationData: any;
  logoPath: string;
}
export default Vue.extend({
  name: "createAgentApplication",
  components: {
    PageWrapDefault,
    saveChanges: () => import("@/components/Dialog/saveChanges.vue")
  },
  data(): ICreateAgentApplication {
    return {
      errorMessage: "",
      successMessage: "",
      loading: false,
      hasValidApplicationData: false,
      checkEntityType: false,
      stateOptions: selectOptions.states,
      streetTypeOptions: selectOptions.streetTypes,
      streetDirectionOptions: selectOptions.streetDirections,
      entityTypeOptions: selectOptions.entityTypeOptions,
      throughMGA: null,
      comparativeRater: null,
      currCarrier: null,
      checkboxOptions: selectOptions.marketingPlansCheckBox,
      isAdmin: false,
      testData: testData as any,
      nextRoute: "",
      validationData: {},
      logoPath: ""
    };
  },
  async mounted() {
    try {
      const url = await logoPath();
      if (url) {
        this.logoPath = url;
      }
    } catch (error) {
      this.$bugSnagClient.notify(error);
    }

    //temporary fix
    const createAgencyMain = document.querySelector(
      ".createAgentApplication--main"
    ) as any;
    if (createAgencyMain.parentNode)
      createAgencyMain.parentNode.classList.remove("wrap");
  },
  created() {
    this.SET_EDIT(getDefaultAgentApplications({}));
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  async beforeRouteLeave(to, from, next): Promise<void> {
    const hasUpdates = Object.keys(this.updatedFields).length > 0;
    if (hasUpdates) {
      this.nextRoute = next;
      this.$modal.show("discard-changes-modal");
    } else {
      return next();
    }
  },
  computed: {
    ...agentApplicationsMapState(["editing", "makingApiRequest"]),
    checkOther(): any {
      return this.editing?.marketingPlans.includes("Other");
    },
    updatedFields(): any {
      const edited = this.editing;
      const allowedEmpty = [
        "physicalAddress.unitNumber",
        "mailingAddress.unitNumber",
        "mailingAddress.streetType",
        "physicalAddress.streetType",
        "mailingAddress.streetDirection",
        "physicalAddress.streetDirection"
      ];
      const original = getDefaultAgentApplications({});
      return edited && original
        ? objectDifference(edited, original, allowedEmpty)
        : {};
    },
    agentApplicationFormFields() {
      return agentApplicationForm;
    },
    validateMarketsAccessedThroughMG(): boolean {
      if (
        this.editing?.anyMarketsAccessedThroughMGA &&
        !this.editing?.marketsAccessedThroughMGA
      ) {
        return false;
      }
      return true;
    },
    validateCompanyUsesComparativeRater(): boolean {
      if (
        this.editing?.agencyUsesComparativeRater &&
        !this.editing?.comparativeRaterUsedByAgency
      ) {
        return false;
      }
      return true;
    },
    validateAtlasWillReplaceCurrentCarrier(): boolean {
      if (
        this.editing?.atlasWillReplaceCurrentCarrier &&
        !this.editing?.currentCarrier
      ) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...agentApplicationsMapMutations(["SET_EDIT"]),
    ...agentApplicationsMapMutations({ editField: types.SET_EDIT_FIELD }),
    ...agentApplicationsMapActions(["createAgentApplications"]),
    getLogoPath() {
      return logoPath();
    },
    onlyNumberKey($event: any) {
      var ASCIICode = $event.which ? $event.which : $event.keyCode;
      if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
        $event.preventDefault();
      }
    },
    formValidationHandler($event: any) {
      this.validationData = $event;
    },
    formFieldChangeHandler({ key, value }: { key: any; value: any }): void {
      const addressKeys = ["physicalAddress", "mailingAddress"];
      if (addressKeys.includes(key)) {
        const { fieldKey, value: fieldValue } = value;
        if (fieldKey.includes("isSameAsPhysicalAddress")) {
          sameAsPhysicalAddressChangedHandler(
            key,
            fieldValue,
            this.editing?.physicalAddress || {},
            this.editField
          );
        }
        this.editField({
          key: `${key}.${fieldKey}` as any,
          value: fieldValue
        });
        if (
          key === "physicalAddress" &&
          this.editing &&
          this.editing.mailingAddress.isSameAsPhysicalAddress
        ) {
          this.editField({
            key: `mailingAddress.${fieldKey}` as any,
            value: fieldValue
          });
        }
      } else if (key === "commercialBusinessSplit") {
        this.handleCommercialBusinessSplit(value);
      } else if (key === "personalBusinessSplit") {
        this.handlePersonalBusinessSplit(value);
      } else {
        this.editField({
          key,
          value
        });
      }
    },
    addressChangeHandler(key: string, event: { fieldKey: string; value: any }) {
      this.editField({
        key: `${key}.${event.fieldKey}` as any,
        value: event.value
      });
    },
    changeTaxId(value: any) {
      this.editField({ key: "fein", value: value });
    },
    handlePersonalBusinessSplit(value: any) {
      this.editField({
        key: "commercialBusinessSplit",
        value: 100 - value
      });
      this.editField({
        key: "personalBusinessSplit",
        value: value
      });
    },
    handleCommercialBusinessSplit(value: any) {
      this.editField({
        key: "commercialBusinessSplit",
        value: value
      });
      this.editField({
        key: "personalBusinessSplit",
        value: 100 - value
      });
    },
    fillData() {
      this.SET_EDIT(this.testData);
    },
    async submit(): Promise<void> {
      try {
        if (this.hasValidApplicationData) {
          const registrationData: Record<string, any> = {
            ...omit(this.editing, "other")
          };
          if (this.checkOther) {
            const indexOfOther = registrationData.marketingPlans.indexOf(
              "Other"
            );
            if (indexOfOther != -1) {
              registrationData.marketingPlans[
                indexOfOther
              ] = this.editing?.other;
            }
          }
          this.loading = true;
          await this.createAgentApplications(registrationData as any);
          this.SET_EDIT({} as any);
          this.$router.push("agent-application/success");
        } else {
          this.errorMessage =
            "Please attend to the fields with errors and try again\nEntries with error have error descriptions marked in red.";
          this.$appNotifyError(this.errorMessage);
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.$appNotifyError(this.errorMessage);
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    updateIsValid() {
      if (this.editing && this.$refs) {
        const criteria: boolean[] = [
          this.validationData.formIsValid,
          !!(
            this.editing.marketingPlans &&
            this.editing.marketingPlans.length >= 3
          ),
          this.validateMarketsAccessedThroughMG,
          this.validateCompanyUsesComparativeRater,
          this.validateAtlasWillReplaceCurrentCarrier
        ];
        this.hasValidApplicationData = criteria.every(
          (criterion: boolean) => criterion
        );
      } else {
        return false;
      }
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "submitApplication":
          this.submit();
          break;
        case "cancel":
          this.$router.push(`/`).catch(() => {});
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
          this.$appNotifyError(this.errorMessage);
      }
    },
    async beforeRouteLeaveSave() {
      this.$modal.hide("discard-changes-modal");
    },
    async beforeRouteLeaveDiscardChanges() {
      try {
        this.$modal.hide("discard-changes-modal");
        this.nextRoute();
      } catch (error) {
        this.nextRoute(false);
        this.$bugSnagClient.notify(error);
      }
    },
    formFieldBlur({ key, value }: { key: string; value?: string }) {
      if (key === "personalLinesPremiumVolumeInLast6Months") {
        const formatted = formatCurrencyFloat(value);

        this.editField({
          key: "personalLinesPremiumVolumeInLast6Months",
          value: formatted
        });
      }
    }
  },
  watch: {
    updatedFields: {
      handler() {
        this.updateIsValid();
      }
    },
    editing: {
      handler() {
        this.updateIsValid();
      }
    }
  }
});
