import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";

export const agentApplicationForm: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useTextField({
          key: "agencyName",
          label: __getText("agentApplication", "agencyName"),
          required: true
        }),
        useField({
          key: "fein",
          label: __getText("agentApplication", "fein"),
          type: "taxId",
          required: true
        })
      ],
      { layout: "2-col" }
    ),
    ...useFormFieldGroup(
      [
        useSelectField({
          key: "entityType",
          label: __getText("agentApplication", "entityType"),
          options: selectOptions.entityTypeOptions,
          required: true,
          multiple: false
        }),
        useTextField(
          {
            key: "companyWebAddress",
            validations: [
              {
                method: "custom",
                customValidator: (value: string) => {
                  if (value) {
                    try {
                      const newUrl = new URL(value);
                      return (
                        newUrl.protocol === "http:" ||
                        newUrl.protocol === "https:"
                      );
                    } catch (error) {
                      return false;
                    }
                  }
                  return true;
                },
                error: "Invalid company web address"
              }
            ],
            label: __getText("agentApplication", "companyWebAddress"),
            required: true
          },
          ["ml-0 mr-2 pr-2"]
        )
        // useTextField({
        //   key: "firstName",
        //   label: __getText("agentApplication", "firstName"),
        //   required: true
        // })
      ],
      { layout: "2-col" }
    ),
    // ...useFormFieldGroup(
    //   [
    //     useTextField({
    //       key: "lastName",
    //       label: __getText("agentApplication", "lastName"),
    //       required: true
    //     }),
    //     useTextField({
    //       key: "email",
    //       label: __getText("agentApplication", "email"),
    //       required: true,
    //       validations: [
    //         {
    //           method: "email",
    //           error: "Email is invalid"
    //         }
    //       ]
    //     })
    //   ],
    //   { layout: "2-col" }
    // ),
    ...useFormFieldGroup(
      [
        useField({
          key: "fax",
          label: __getText("agentApplication", "fax"),
          required: true,
          type: "phone"
        }),
        useField({
          key: "phone",
          label: __getText("agentApplication", "phone"),
          required: true,
          type: "phone"
        })
        // useTextField({
        //   key: "companyWebAddress",
        //   label: __getText("agentApplication", "companyWebAddress"),
        //   required: true
        // })
      ],
      { layout: "3-col" }
    )
  ]),

  useField({
    key: "physicalAddress",
    label: ["fields", "physicalAddressLabel"],
    required: true,
    type: "address",
    addressType: "physicalAddress",
    validateAddress: false
  }),
  useField({
    key: "mailingAddress",
    label: __getText("fields", "mailingAddressLabel"),
    required: true,
    type: "address",
    addressType: "mailingAddress",
    isPOBOXMailingAddress: "true",
    allowSyncingWithPhysicalAddress: true
  }),
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "ownersFirstName",
            label: __getText("agentApplication", "ownersFirstName"),
            required: true
          }),
          useTextField({
            key: "ownersLastName",
            label: __getText("agentApplication", "ownersLastName"),
            required: true
          }),
          useTextField({
            key: "ownersEmail",
            label: __getText("agentApplication", "ownersEmail"),
            required: true,
            validations: [
              {
                method: "email",
                error: "Email is invalid"
              }
            ]
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "supervisorsEmail",
            label: __getText("agentApplication", "supervisorsEmail"),
            required: true,
            validations: [
              {
                method: "email",
                error: "Email is invalid"
              }
            ]
          }),
          useField({
            key: "mainPersonalLinesContact",
            label: __getText("agentApplication", "mainPersonalLinesContact"),
            required: true,
            type: "phone"
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "commercialBusinessSplit",
            label: __getText("agentApplication", "commercialBusinessSplit"),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          }),
          useTextField({
            key: "personalBusinessSplit",
            label: __getText("agentApplication", "personalBusinessSplit"),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "totalNumberOfAgencyEmployees",
            label: __getText(
              "agentApplication",
              "totalNumberOfAgencyEmployees"
            ),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ],
            inputTextClass: "mt-4"
          }),
          useTextField({
            key: "totalNumberOfPersonalLineStaff",
            label: __getText(
              "agentApplication",
              "totalNumberOfPersonalLineStaff"
            ),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ],
            inputTextClass: "mt-4"
          }),
          useTextField({
            key: "totalCombinedYearsForPersonalLinesStaff",
            label: __getText(
              "agentApplication",
              "totalCombinedYearsForPersonalLinesStaff"
            ),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ],
            inputTextClass: "mt-4"
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "motiveForSeekingAppointment",
            label: __getText("agentApplication", "motiveForSeekingAppointment"),
            required: true,
            type: "textarea"
          }),
          useField({
            key: "factorsInfluencingPlacementOfResidentialPropertyBusiness",
            label: __getText(
              "agentApplication",
              "factorsInfluencingPlacementOfResidentialPropertyBusiness"
            ),
            required: true,
            type: "textarea"
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "primaryFocusWhenSellingPropertyPolicy",
            label: __getText(
              "agentApplication",
              "primaryFocusWhenSellingPropertyPolicy"
            ),
            required: true,
            type: "textarea",
            inputTextClass: "mt-8"
          }),
          useField({
            key: "familiarResidentialProductForms",
            label: __getText(
              "agentApplication",
              "familiarResidentialProductForms"
            ),
            required: true,
            type: "textarea",
            inputTextClass: "mt-8"
          }),
          useField({
            key: "underwritingChallenges",
            label: __getText("agentApplication", "underwritingChallenges"),
            required: true,
            type: "textarea",
            inputTextClass: "mt-8"
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "personalLinesPremiumVolumeInLast6Months",
            label: __getText(
              "agentApplication",
              "personalLinesPremiumVolumeInLast6Months"
            ),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          }),
          useTextField({
            key: "personalLinesTotalWrittenPremium",
            label: "Personal lines total written premium ($)",
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          })
        ],
        { layout: "2-col" }
      ),
      {
        element: "html",
        id: "sectionOne",
        tagName: "div",
        classList: ["mb-8"],
        children: [
          {
            element: "html",
            id: "headerOne",
            tagName: "h4",
            innerText: "Top 4 Residential Property Carriers",
            classList: ["font-normal", "text-sm"]
          }
        ]
      },
      ...useFormFieldGroup(
        [
          useTextField({
            key: "top4ResidentialPropertyCarriers[0]",
            label: __getText("agentApplication", "carrier1"),
            required: true
          }),
          useTextField({
            key: "top4ResidentialPropertyCarriers[1]",
            label: __getText("agentApplication", "carrier2"),
            required: true
          }),
          useTextField({
            key: "top4ResidentialPropertyCarriers[2]",
            label: __getText("agentApplication", "carrier3"),
            required: true
          }),
          useTextField({
            key: "top4ResidentialPropertyCarriers[3]",
            label: __getText("agentApplication", "carrier4"),
            required: true
          })
        ],
        { layout: "4-col" }
      ),

      ...useFormFieldGroup(
        [
          useField(
            {
              key: "anyMarketsAccessedThroughMGA",
              label: __getText("agentApplication", "marketsAccessedThroughMGA"),
              preamble: __getText(
                "agentApplication",
                "marketsAccessedThroughMGA"
              ),
              required: false,
              type: "radio_button_group",
              options: [
                { label: "Yes", value: true },
                { label: "No", value: false }
              ]
            },
            ["mr-10"]
          ),
          useField({
            key: "agencyUsesComparativeRater",
            label: __getText("agentApplication", "agencyUsesComparativeRater"),
            preamble: __getText(
              "agentApplication",
              "agencyUsesComparativeRater"
            ),
            required: false,
            type: "radio_button_group",
            options: [
              { label: "Yes", value: true },
              { label: "No", value: false }
            ]
          }),
          useField({
            key: "atlasWillReplaceCurrentCarrier",
            label: __getText(
              "agentApplication",
              "atlasWillReplaceCurrentCarrier"
            ),
            preamble: __getText(
              "agentApplication",
              "atlasWillReplaceCurrentCarrier"
            ),
            required: false,
            type: "radio_button_group",
            options: [
              { label: "Yes", value: true },
              { label: "No", value: false }
            ]
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "marketsAccessedThroughMGA",
            label: __getText("agentApplication", "ifYes"),
            required: false
          }),
          useTextField({
            key: "comparativeRaterUsedByAgency",
            label: __getText("agentApplication", "ifYes"),
            required: false
          }),
          useTextField({
            key: "currentCarrier",
            label: __getText("agentApplication", "ifYes"),
            required: false
          })
        ],
        { layout: "3-col" }
      ),
      {
        element: "html",
        id: "sectionOne",
        tagName: "div",
        classList: ["mb-8"],
        children: [
          {
            element: "html",
            id: "headerOne",
            tagName: "h4",
            innerText:
              "What production commitment can you make to Atlas General Agency?",
            classList: ["font-normal", "text-sm"]
          }
        ]
      },
      ...useFormFieldGroup(
        [
          useTextField({
            key: "applicationsPerMonth",
            label: __getText("agentApplication", "permonth"),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          }),
          useTextField({
            key: "firstYearPremiumCommitment",
            label: __getText("agentApplication", "premium"),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup([
        useField({
          key: "howOurMarketFitsWithOtherMarketsAgentRepresents",
          label: __getText("agentApplication", "howWill"),
          required: true,
          type: "textarea"
        })
      ]),
      ...useFormFieldGroup([
        useSelectField({
          key: "howDidYouHearAboutAtlas",
          label: "How did you hear about Atlas?",
          options: selectOptions.howDidYouHearAboutAtlas,
          required: true,
          allowCreate: true,
          filterable: true
        })
      ]),
      ...useFormFieldGroup(
        [
          {
            element: "field",
            classList: ["text-sm"],
            properties: {
              key: "marketingPlans",
              required: true,
              type: "check_box_group",
              preamble: __getText("agentApplication", "whatMarketingPlans"),
              options: selectOptions.marketingPlansCheckBoxFormBuilder
            }
          }
        ],
        { layout: "full" }
      ),
      ...useFormFieldGroup([
        useTextField(
          {
            key: "other",
            label: __getText("agentApplication", "other"),
            required: true,
            conditions: {
              and: [
                {
                  field: "marketingPlans",
                  value: "Other",
                  operator: "includes"
                }
              ]
            }
          },
          ["-mt-10"]
        )
      ]),
      {
        element: "html",
        id: "sectionOne",
        tagName: "div",
        classList: ["mb-5 -mt-5"],
        children: [
          {
            element: "html",
            id: "headerOne",
            tagName: "span",
            innerText: "Select at least 3 of the above options",
            classList: ["font-normal", "text-xs"]
          }
        ]
      }
    ],
    {
      title: "Additional Information",
      description: ""
    }
  )
];
